import React, { Component } from "react"
import PropTypes from "prop-types"

import cx from "classnames"
import styles from "./Button.module.scss"
import { Link } from "components/link/Link"
import ButtonArrow from "./ButtonArrow"

export class ButtonLink extends Component {
  render() {
    const {
      children,
      className: classNameProp,
      to,
      href,
      variant,
      dark,
      light,
      full,
      disabled,
      target,
      rel,
      tabIndex: tabIndexProp,
      ...rest
    } = this.props

    const className = cx(
      styles.button,
      {
        [styles.buttonPrimary]: variant === "primary",
        [styles.buttonSecondary]: variant === "secondary",
        [styles.buttonDark]: dark,
        [styles.buttonLight]: light,
        [styles.buttonFull]: full,
      },
      classNameProp
    )

    const isPrimary = variant === "primary"

    if (to) {
      return (
        <Link to={to} className={className} {...rest}>
          {children}
          {isPrimary && <ButtonArrow />}
        </Link>
      )
    } else if (href) {
      return (
        <a
          href={href}
          className={className}
          target={target}
          rel={target === "_blank" ? "noopener noreferrer" : rel}
          {...rest}
        >
          {children}
          {isPrimary && <ButtonArrow />}
        </a>
      )
    }
  }
}

ButtonLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  dark: PropTypes.bool,
  light: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  rel: PropTypes.string,
}

ButtonLink.defaultProps = {
  variant: "secondary",
  dark: false,
  light: false,
  full: false,
  disabled: false,
  target: "",
  rel: "",
}
