import React, { Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import shallowequal from "shallowequal"
import raf from "raf"
import shouldUpdate from "./shouldUpdate"
import s from "./Headroom.module.scss"

export class Headroom extends Component {
  static propTypes = {
    className: PropTypes.string,
    parent: PropTypes.func,
    children: PropTypes.any.isRequired,
    disable: PropTypes.bool,
    upTolerance: PropTypes.number,
    downTolerance: PropTypes.number,
    pinStart: PropTypes.number,
    calcHeightOnResize: PropTypes.bool,
    onPin: PropTypes.func,
    onUnpin: PropTypes.func,
    onUnfix: PropTypes.func,
  }

  static defaultProps = {
    parent: () => window,
    disable: false,
    upTolerance: 5,
    downTolerance: 0,
    pinStart: 0,
    calcHeightOnResize: true,
    onPin: () => null,
    onUnpin: () => null,
    onUnfix: () => null,
  }

  static getDerivedStateFromProps(props, state) {
    if (props.disable && state.state !== "unfixed") {
      return {
        animation: false,
        state: "unfixed",
      }
    }

    return null
  }

  constructor(props) {
    super(props)

    // Class variables
    this.currentScrollY = 0
    this.lastKnownScrollY = 0
    this.scrollTicking = false
    this.resizeTicking = false
    this.state = {
      state: "unfixed",
    }
  }

  componentDidMount() {
    this.setHeightOffset()

    if (!this.props.disable) {
      this.props.parent().addEventListener("scroll", this.handleScroll)

      if (this.props.calcHeightOnResize) {
        this.props.parent().addEventListener("resize", this.handleResize)
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !shallowequal(this.props, nextProps) ||
      !shallowequal(this.state, nextState)
    )
  }

  componentDidUpdate(prevProps, prevState) {
    // If children have changed, remeasure height.
    if (prevProps.children !== this.props.children) {
      this.setHeightOffset()
    }

    // Add/remove event listeners when re-enabled/disabled
    if (!prevProps.disable && this.props.disable) {
      this.props.parent().removeEventListener("scroll", this.handleScroll)
      this.props.parent().removeEventListener("resize", this.handleResize)

      if (prevState.state !== "unfixed" && this.state.state === "unfixed") {
        // this.props.onUnfix()
      }
    } else if (prevProps.disable && !this.props.disable) {
      this.props.parent().addEventListener("scroll", this.handleScroll)

      if (this.props.calcHeightOnResize) {
        this.props.parent().addEventListener("resize", this.handleResize)
      }
    }
  }

  componentWillUnmount() {
    this.props.parent().removeEventListener("scroll", this.handleScroll)
    window.removeEventListener("scroll", this.handleScroll)
    this.props.parent().removeEventListener("resize", this.handleResize)
  }

  setRef = (ref) => (this.inner = ref)

  setHeightOffset = () => {
    this.setState({
      height: this.inner ? this.inner.offsetHeight : "",
    })
    this.resizeTicking = false
  }

  getScrollY = () => {
    if (this.props.parent().pageYOffset !== undefined) {
      return this.props.parent().pageYOffset
    } else if (this.props.parent().scrollTop !== undefined) {
      return this.props.parent().scrollTop
    } else {
      return (
        document.documentElement ||
        document.body.parentNode ||
        document.body
      ).scrollTop
    }
  }

  getViewportHeight = () =>
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight

  getDocumentHeight = () => {
    const body = document.body
    const documentElement = document.documentElement

    return Math.max(
      body.scrollHeight,
      documentElement.scrollHeight,
      body.offsetHeight,
      documentElement.offsetHeight,
      body.clientHeight,
      documentElement.clientHeight
    )
  }

  getElementPhysicalHeight = (elm) =>
    Math.max(elm.offsetHeight, elm.clientHeight)

  getElementHeight = (elm) =>
    Math.max(elm.scrollHeight, elm.offsetHeight, elm.clientHeight)

  getScrollerPhysicalHeight = () => {
    const parent = this.props.parent()

    return parent === window || parent === document.body
      ? this.getViewportHeight()
      : this.getElementPhysicalHeight(parent)
  }

  getScrollerHeight = () => {
    const parent = this.props.parent()

    return parent === window || parent === document.body
      ? this.getDocumentHeight()
      : this.getElementHeight(parent)
  }

  isOutOfBound = (currentScrollY) => {
    const pastTop = currentScrollY < 0

    const scrollerPhysicalHeight = this.getScrollerPhysicalHeight()
    const scrollerHeight = this.getScrollerHeight()

    const pastBottom = currentScrollY + scrollerPhysicalHeight > scrollerHeight

    return pastTop || pastBottom
  }

  handleScroll = () => {
    if (!this.scrollTicking) {
      this.scrollTicking = true
      raf(this.update)
    }
  }

  handleResize = () => {
    if (!this.resizeTicking) {
      this.resizeTicking = true
      raf(this.setHeightOffset)
    }
  }

  unpin = () => {
    this.props.onUnpin()
    // console.log("onUnpin")

    this.setState({
      animation: true,
      state: "unpinned",
    })
  }

  unpinSnap = () => {
    this.props.onUnpin()
    // console.log("onUnpinSnap")

    this.setState({
      animation: false,
      state: "unpinned",
    })
  }

  pin = () => {
    this.props.onPin()
    // console.log("onPin")

    this.setState({
      animation: true,
      state: "pinned",
    })
  }

  unfix = () => {
    this.props.onUnfix()
    // console.log("onUnfix")

    this.setState({
      animation: false,
      state: "unfixed",
    })
  }

  update = () => {
    this.currentScrollY = this.getScrollY()

    if (!this.isOutOfBound(this.currentScrollY)) {
      const { action } = shouldUpdate(
        this.lastKnownScrollY,
        this.currentScrollY,
        this.props,
        this.state
      )

      if (action === "pin") {
        this.pin()
      } else if (action === "unpin") {
        this.unpin()
      } else if (action === "unpin-snap") {
        this.unpinSnap()
      } else if (action === "unfix") {
        this.unfix()
      }
    }

    this.lastKnownScrollY = this.currentScrollY
    this.scrollTicking = false
  }

  render() {
    const wrapperStyles = {
      height: this.state.height ? this.state.height : null,
    }

    return (
      <div style={wrapperStyles} className={s.wrapper}>
        <div
          ref={this.setRef}
          className={cx(s.headroom, {
            [s.unfixed]: this.state.state === "unfixed",
            [s.scrolled]: this.state.state === "scrolled",
            [s.unpinned]: this.state.state === "unpinned",
            [s.pinned]: this.state.state === "pinned",
            [s.scrolled]: this.state.animation,
            [s.disableAnimation]: !this.state.animation,
          })}
        >
          {this.props.children}
        </div>
      </div>
    )
  }
}
