let animationIndex = 0

export const animate = (attr = {}) => {
  if (attr.disabled) return

  const name = attr.name || "fade-up"
  const index = attr.index || animationIndex
  const duration = attr.duration || 500
  const delay = attr.delay || 0
  const delayMultiple = attr.delayMultiple || 1
  const easing = attr.easing || "ease-in-out"

  return {
    "data-sal": name,
    "data-sal-delay": delay * (index + delayMultiple),
    "data-sal-duration": duration,
    "data-sal-easing": easing,
  }
}
