/* eslint-disable  jsx-a11y/img-redundant-alt */
import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import styles from "./PreFooter.module.scss"
import { Section } from "../section"
import { animate } from "../../utilities/animate"
import { useBreakpoint } from "../../hooks/useBreakpoint"
import Img from "gatsby-image"

const PreFooter = ({ children, className, image, isResearch, ...rest }) => {
  const breakpoint = useBreakpoint()

  const sectionColor =
    breakpoint === "xs" || breakpoint === "sm" ? "light" : "black"

  return (
    <div {...rest}>
      <div
        className={cn(styles.wrapper, {
          [styles.isResearch]: isResearch,
        })}
      >
        <Section
          noTopSpacing={true}
          noBottomSpacing={true}
          className={cn(styles.section, className)}
          background={sectionColor}
        >
          <div className={styles.contentWrap}>
            <div className={styles.overflowContainer} />
            <div className={styles.background}>
              <div className={styles.imageWrapper}>
                <div
                  className={cn(
                    styles.imageHeightContainer,
                    image?.containerClassname
                  )}
                  {...animate({ delay: 100 })}
                >
                  {image?.fluid ? (
                    <Img
                      className={styles.image}
                      fluid={image?.fluid}
                      alt={image?.alt}
                      style={{ position: "absolute" }}
                      fadeIn={false}
                      loading={"eager"}
                    />
                  ) : (
                    <img
                      className={styles.image}
                      src={image?.src}
                      alt={image?.alt}
                      srcSet={`${image?.src2x} 2x, ${image?.src} 1x`}
                    />
                  )}
                </div>
              </div>
              <div className={styles.content}>{children}</div>
            </div>
          </div>
        </Section>
      </div>
      <div className={styles.bottomPadding} />
    </div>
  )
}

PreFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    src2x: PropTypes.string,
    containerClassname: PropTypes.string,
  }),
  isResearch: PropTypes.bool,
}

PreFooter.defaultProps = {
  className: "",
  children: undefined,
  image: undefined,
  isResearch: false,
}

export default PreFooter
