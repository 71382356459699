import React, { useEffect } from "react"
import AOS from "aos"
import sal from "sal.js"
import { Header, MantraHeader } from "components/header"
import { Footer } from "components/footer"
import { useWindowOutOfFocusTitle } from "../../hooks/useWindowOutOfFocusTitle"
import NewsletterFooter from "../mantra/newsletter-footer/NewsletterFooter"

export const Layout = (props) => {
  const { children, location, isMantra } = props

  useEffect(() => {
    sal({
      selector: "[data-sal]",
      animateClassName: "animate",
      disabledClassName: "reveal-disabled",
      threshold: 0.2,
    })

    AOS.init({})

    console.log(
      "%cKnow some code, do you? We're hiring! https://martian.ventures/careers",
      "color: #E25033; font-size: 18px; font-weight: bold;"
    )

    // ININITALIZE GOOGLE OPTIMIZE EXPERIMENT ON 'optimize.activate'
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ event: "optimize.activate" })
  }, [location])

  useWindowOutOfFocusTitle()

  useEffect(() => {
    const setVh = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty("--vh", `${vh}px`)
    }
    window.addEventListener("resize", setVh)
    setVh()

    return () => {
      window.removeEventListener("resize", setVh)
    }
  }, [])

  if (isMantra) {
    return (
      <div>
        <MantraHeader location={location} />
        {children}
        <Footer />
      </div>
    )
  } else {
    return (
      <div>
        <Header location={location} />
        {children}
        <NewsletterFooter />
        <Footer />
      </div>
    )
  }
}
