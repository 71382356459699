import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import styles from "./Section.module.scss"
import { Holder } from "components/holder/Holder"
import { animate } from "utilities/animate"
import { SectionHeading } from "./SectionHeading"

function getPadding(color) {
  if (color === "transparent") {
    return "big"
  }
  if (color === "light") {
    return "big"
  }
  if (color === "black") {
    return "small"
  }
  if (color === "dark") {
    return "small"
  }
  return undefined
}

export const Section = (props) => {
  const {
    className,
    id,
    order,
    title,
    background,
    noTopSpacing,
    noBottomSpacing,
    innerRef,
    children,
    afterChildren,
    headingMarginBottom,
    padding,
    ...rest
  } = props

  const derivedPadding = padding ? padding : getPadding(background)

  return (
    <section
      id={id}
      ref={innerRef}
      className={cx(
        styles.wrapper,
        {
          [styles.wrapperDark]: background === "dark",
          [styles.wrapperLight]: background === "light",
          [styles.wrapperBlack]: background === "black",
          [styles.wrapperTransparent]: background === "transparent",
          [styles.noTopSpacing]: noTopSpacing,
          [styles.noBottomSpacing]: noBottomSpacing,
          [styles.paddingSmall]: derivedPadding === "small",
          [styles.paddingBig]: derivedPadding === "big",
          [styles.paddingBigTopSmall]: derivedPadding === "bigTopSmall",
          [styles.paddingBigBottomSmall]: derivedPadding === "bigBottomSmall",
        },
        className
      )}
      {...rest}
    >
      <Holder>
        {title && (
          <SectionHeading
            marginBottom={headingMarginBottom}
            order={order}
            animated={true}
          >
            {title}
          </SectionHeading>
        )}
        {children}
      </Holder>
      {afterChildren}
    </section>
  )
}

Section.propTypes = {
  background: PropTypes.oneOf(["light", "dark", "black", "transparent"]),
  order: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  title: PropTypes.string,
  noTopSpacing: PropTypes.bool,
  noBottomSpacing: PropTypes.bool,
  children: PropTypes.node,
  headingMarginBottom: PropTypes.oneOf(["sm", "md"]),
  padding: PropTypes.oneOf(["small", "big", "bigTopSmall", "bigBottomSmall"]),
}

Section.defaultProps = {
  background: "light",
  order: "",
  title: "",
  noTopSpacing: false,
  noBottomSpacing: false,
  children: null,
  headingMarginBottom: undefined,
}

// Title

export const SectionTitle = (props) => {
  const { children, className, noBottomSpacing, doubleBottomSpacing } = props
  return (
    <h2
      className={cx(
        styles.title,
        {
          [styles.titleBottomSpacing]: !noBottomSpacing && !doubleBottomSpacing,
          [styles.titleDoubleBottomSpacing]:
            !noBottomSpacing && doubleBottomSpacing,
        },
        className
      )}
      {...animate()}
    >
      {children}
    </h2>
  )
}

SectionTitle.propTypes = {
  children: PropTypes.node,
  noBottomSpacing: PropTypes.bool,
  doubleBottomSpacing: PropTypes.bool,
}

SectionTitle.defaultProps = {
  children: null,
  noBottomSpacing: false,
  doubleBottomSpacing: false,
}

// Subtitle

export const SectionSubtitle = (props) => {
  const { children, className, order, animated } = props

  return (
    <h3
      className={cx(styles.subtitle, className)}
      {...animate({ disabled: !animated })}
    >
      {order && <span className={styles.subtitleNum}>{order}</span>} {children}
    </h3>
  )
}

SectionSubtitle.propTypes = {
  children: PropTypes.node,
  animated: PropTypes.bool,
}

SectionSubtitle.defaultProps = {
  children: null,
  animated: false,
}

// Subtitle

export const SectionText = (props) => {
  const { children, className, noBottomSpacing, animated } = props
  return (
    <div
      className={cx(
        styles.text,
        {
          [styles.textBottomSpacing]: !noBottomSpacing,
        },
        className
      )}
      {...animate({ disabled: !animated })}
    >
      {children}
    </div>
  )
}

SectionText.propTypes = {
  children: PropTypes.node,
  noBottomSpacing: PropTypes.bool,
  animated: PropTypes.bool,
}

SectionText.defaultProps = {
  children: null,
  noBottomSpacing: false,
  animated: false,
}
