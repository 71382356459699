import { useEvent } from "react-use"
import { useRef, useState } from "react"

export const useWindowOutOfFocusTitle = () => {
  const [oldTitle, setOldTitle] = useState("")
  const timeout = useRef(null)

  useEvent("visibilitychange", () => {
    if (document.visibilityState === "hidden") {
      setOldTitle(document.title)
      // timeout to prevent title change on refresh
      timeout.current = setTimeout(() => {
        document.title = "Come back to Mars"
      }, 0)
    } else {
      clearTimeout(timeout?.current)
      document.title = oldTitle
    }
  })
}
