import React, { Component } from "react"
import cx from "classnames"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { Headroom } from "components/headroom/Headroom"
import { withGlobalContext } from "components/context/GlobalContext"
import { Link } from "components/link/Link"
import Logo from "./assets/logo.js"
import styles from "./Header.module.scss"

class HeaderComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      route: props.location ? this.getRoute(props.location.pathname) : null,
      fullscreen: false,
      disableHeadroom: false,
      pinned: false,
    }

    this.wrapper = React.createRef()
  }

  getRoute = (path) => {
    if (!path || path === "/") {
      return "/"
    } else {
      return path.split("/")[1]
    }
  }

  handleToggleOnClick = () => {
    this.setState({ fullscreen: !this.state.fullscreen })

    if (!this.state.fullscreen) {
      disableBodyScroll(this.wrapper.current)
    } else {
      enableBodyScroll(this.wrapper.current)
    }
  }

  handleLinkOnClick = (e) => {
    const { route } = this.state
    const target = this.getRoute(e.target.pathname)

    if (target === route) {
      e.preventDefault()
      window.scrollTo(0, 0)
    }

    this.setState({ fullscreen: false })
    enableBodyScroll(this.wrapper.current)
  }

  componentDidUpdate(prevProps, prevState) {
    const { headerScrollEvents } = this.props.context
    const prevHeaderScrollEvents = prevProps.context.headerScrollEvents
    if (prevHeaderScrollEvents !== headerScrollEvents) {
      this.setState({ disableHeadroom: !headerScrollEvents })
    }
  }

  componentWillUnmount = () => {
    clearAllBodyScrollLocks()
  }

  render() {
    const { fullscreen, disableHeadroom, pinned } = this.state

    return (
      <Headroom
        disable={disableHeadroom}
        onPin={() => this.setState({ pinned: true })}
        onUnpin={() => this.setState({ pinned: false })}
        onUnfix={() => this.setState({ pinned: false })}
      >
        <div
          ref={this.wrapper}
          className={cx(styles.wrapper, {
            [styles.wrapperDefault]: true,
            [styles.fullscreen]: fullscreen,
            [styles.pinned]: pinned,
          })}
        >
          <div className={styles.meta}>
            <Link
              to="/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={styles.logo}
              title="Martian &amp; Machine"
            >
              <Logo />
            </Link>
            <button
              onClick={this.handleToggleOnClick}
              className={cx(styles.toggle, {
                [styles.toggleActive]: fullscreen,
              })}
            >
              {fullscreen ? "Close" : "Menu"}
            </button>
          </div>
          <div className={styles.nav}>
            <Link
              to="/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.navLink, styles.navLinkHome)}
              activeClassName={styles.navLinkActive}
            >
              Home
            </Link>
            <a
              target={"_blank"}
              rel={"noopener noreferrer"}
              href="https://corporates.martian.ventures/"
              className={cx(styles.navLink)}
            >
              For corporates
            </a>
            <Link
              to="/about-us/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.navLink)}
              activeClassName={styles.navLinkActive}
            >
              About us
            </Link>
            <Link
              to="/portfolio/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.navLink)}
              activeClassName={styles.navLinkActive}
            >
              Portfolio
            </Link>
            <Link
              to="/careers/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.navLink)}
              activeClassName={styles.navLinkActive}
            >
              Careers
            </Link>
            <Link
              to="/mantra/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.navLink)}
              activeClassName={styles.navLinkActive}
            >
              Mantra
            </Link>
            <a
              target={"_blank"}
              rel={"noopener noreferrer"}
              href="https://circle.martian.ventures/"
              className={cx(styles.navLink)}
            >
              Circle
            </a>
          </div>
        </div>
      </Headroom>
    )
  }
}

export const Header = withGlobalContext(HeaderComponent)
