import React from "react"
import styles from "./Button.module.scss"

const ButtonArrow = () => (
  <>
    <span className={styles.border} />
    <span className={styles.arrow}>
      <span className={styles.shaft} />
    </span>
  </>
)

export default ButtonArrow
