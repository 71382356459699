import { createBreakpoint } from "react-use"

export const useBreakpoint = createBreakpoint({
  xs: 0,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1440,
  xxl: 1920,
})
