import React from "react"
import cx from "classnames"
import { Link } from "components/link/Link"
import { Section } from "../section"
import s from "./Footer.module.scss"
import { useBreakpoint } from "../../hooks/useBreakpoint"

export const Footer = () => {
  const breakpoint = useBreakpoint()

  return (
    <footer>
      <Section className={cx(s.footer)} background={"black"}>
        <div className={s.container}>
          <a
            href={"mailto:hello@martian.ventures"}
            target={"_blank"}
            rel={"noopener noreferrer"}
            className={cx(s.mail, "h1")}
          >
            hello@martian.ventures
          </a>
          <div className={s.textWrapper}>
            <p
              className={cx(s.text, {
                h4: breakpoint === "xs",
                h5: breakpoint !== "xs",
              })}
            >
              We are always happy to talk to curious and smart people that want
              to build something new!
            </p>
          </div>
          <hr className={cx(s.hr, s.hrTop)} />
        </div>

        <div className={cx(s.containerBottom)}>
          <span className={cx(s.copyright, "h6")}>
            © Martian & Machine GmbH {new Date().getFullYear()}
          </span>

          <hr className={cx(s.hr, s.hrBottom)} />

          <div className={s.links}>
            <div className={cx(s.externalLinks)}>
              <a
                href="https://www.linkedin.com/company/martian-&-machine"
                target="_blank"
                rel="noopener noreferrer"
                className={cx(s.link, "h6")}
              >
                Linkedin
              </a>

              <a
                href="https://www.instagram.com/martianandmachine"
                target="_blank"
                rel="noopener noreferrer"
                className={cx(s.link, "h6")}
              >
                Instagram
              </a>

              <a
                href="https://www.facebook.com/martian.ventures"
                target="_blank"
                rel="noopener noreferrer"
                className={cx(s.link, "h6")}
              >
                Facebook
              </a>
            </div>

            <div className={cx(s.pageLinks)}>
              <Link className={cx(s.link, "h6")} to="/impressum/">
                Impressum
              </Link>

              <Link className={cx(s.link, "h6")} to="/privacy-policy/">
                Privacy Policy
              </Link>

              <Link className={cx(s.link, "h6")} to="/cookie-policy/">
                Cookie Policy
              </Link>
            </div>
          </div>
        </div>
      </Section>
    </footer>
  )
}
