import React, { Component, useCallback, useEffect, useState } from "react"
import PropTypes from "prop-types"
import cx from "classnames"
import { useDropzone } from "react-dropzone"
import ReactSelect from "react-select"
import styles from "./Form.module.scss"
import iconUpload from "./assets/upload.svg"
import iconCheckmark from "../../assets/icons/checkmark.svg"
import iconDelete from "./assets/delete.svg"
import { PlayState, Tween } from "react-gsap"

//-------------------------------
// Input
export class Input extends Component {
  render() {
    const {
      className: classNameProp,
      name,
      type,
      value,
      error,
      full,
      tabIndex,
      disabled,
      dark,
      innerRef,
      ...rest
    } = this.props

    const className = cx([
      styles.input,
      { [styles.inputDark]: dark },
      classNameProp,
    ])

    return (
      <>
        <input
          name={name}
          type={type}
          value={value}
          className={className}
          tabIndex={disabled ? "-1" : tabIndex}
          disabled={disabled}
          ref={innerRef}
          {...rest}
        />
        <Error error={error} />
      </>
    )
  }
}

Input.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  // error: PropTypes.node,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
}

Input.defaultProps = {
  type: "text",
  error: false,
  full: false,
  disabled: false,
  tabIndex: "0",
  innerRef: null,
}

//-------------------------------
// Textarea

export class Textarea extends Component {
  render() {
    const {
      className: classNameProp,
      name,
      error,
      full,
      disabled,
      tabIndex,
      innerRef,
      ...rest
    } = this.props

    const className = cx(styles.textarea, {}, classNameProp)

    return (
      <>
        <textarea
          name={name}
          className={className}
          tabIndex={disabled ? "-1" : tabIndex}
          disabled={disabled}
          ref={innerRef}
          {...rest}
        />
        <Error error={error} />
      </>
    )
  }
}

Textarea.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
}

Textarea.defaultProps = {
  full: false,
  disabled: false,
  tabIndex: "0",
  innerRef: null,
}

//-------------------------------
// Checkbox

export class Checkbox extends Component {
  render() {
    const {
      className: classNameProp,
      id: idProp,
      name,
      label,
      type,
      error,
      full,
      dark,
      disabled,
      tabIndex,
      innerRef,
      ...rest
    } = this.props

    const className = cx(
      styles.checkbox,
      { [styles.checkboxDark]: dark },
      classNameProp
    )
    const id = !idProp ? name : idProp

    return (
      <>
        <input
          id={id}
          name={name}
          type="checkbox"
          className={className}
          tabIndex={disabled ? "-1" : tabIndex}
          disabled={disabled}
          ref={innerRef}
          {...rest}
        />
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
        <Error error={error} />
      </>
    )
  }
}

Checkbox.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.string,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
}

Checkbox.defaultProps = {
  type: "text",
  full: false,
  disabled: false,
  tabIndex: "0",
  innerRef: null,
}

//-------------------------------
// Select

const customStyles = {
  option: (provided, state) => ({
    display: "flex",
    alignItems: "center",
    minHeight: "48px",
    padding: 0,
    margin: "0 15px",
    fontSize: "18px",
    color: state.isSelected || state.isFocused ? "#E25033" : "#161211",
    borderBottom: "1px solid #F3F3F3",
    cursor: "pointer",
  }),
  control: () => ({
    display: "flex",
    minHeight: "48px",
    paddingLeft: "7px",
    fontSize: "18px",
    background: "transparent",
    border: "1px solid #000",
  }),
  indicatorsContainer: () => ({
    width: "11px",
    margin: "20px 15px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  menu: (provided, state) => ({
    ...provided,
    background: "#fff",
    border: "1px solid #3d3d3d !important",
    borderTop: 0,
    borderRadius: 0,
    boxShadow: "none",
  }),
  placeholder: (state) => ({
    color: "#8e8e8e",
  }),
  singleValue: () => ({}),
}

const DropdownIndicator = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 7">
      <g fill="none" fillRule="evenodd">
        <path
          fill="#161211"
          d="M10.607-.05l.707.707-5.657 5.657L0 .657.707-.05l4.949 4.949 4.95-4.95z"
        />
      </g>
    </svg>
  )
}

export class Select extends Component {
  render() {
    const { options, placeholder, isSearchable } = this.props
    return (
      <ReactSelect
        components={{ DropdownIndicator }}
        styles={customStyles}
        options={options}
        placeholder={placeholder}
        isSearchable={isSearchable}
      />
    )
  }
}

Select.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  tabIndex: PropTypes.string,
}

Select.defaultProps = {
  type: "text",
  placeholder: "Select below",
  isSearchable: false,
  disabled: false,
  tabIndex: "0",
}

//-------------------------------
// Dropzone

export const Dropzone = (props) => {
  const { addFile, removeFile, files, placeholder, uploadIcon } = props

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        addFile(file)
      })
    },
    [addFile]
  )

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: "image/*, application/pdf" })

  return (
    <>
      {files.length > 0 && (
        <div className={styles.dropzoneWrapper}>
          {files.map((file, index) => (
            <div
              className={cx(styles.dropzoneBox, styles.dropzoneBoxFile)}
              key={file.name}
            >
              <img
                className={cx(
                  styles.dropzoneBoxIcon,
                  styles.dropzoneBoxIconCheckmark
                )}
                src={iconCheckmark}
                alt="Checkmark"
              />
              {file.path}
              <button
                className={styles.dropzoneBoxFileDelete}
                type="button"
                onClick={() => removeFile(file.name)}
              >
                <img src={iconDelete} alt="Delete" />
              </button>
            </div>
          ))}
        </div>
      )}
      <div {...getRootProps()} className={styles.dropzoneWrapper}>
        <input name={props.name} {...getInputProps()} />
        <div className={cx(styles.dropzoneBox, styles.dropzoneBoxDrop)}>
          <img
            className={styles.dropzoneBoxIcon}
            src={uploadIcon || iconUpload}
            alt="Upload"
          />

          <div className={styles.dropzoneBoxContent}>
            {isDragActive && isDragAccept && <span>{placeholder}</span>}

            {isDragActive && isDragReject && (
              <span>Wrong file format. Only JPG, PNG and PDF.</span>
            )}
            {!isDragActive && placeholder && <span>{placeholder}</span>}
          </div>
        </div>
      </div>
    </>
  )
}

//-------------------------------
// Error

export const Error = ({ error }) => {
  const [animationPlayState, setAnimationPlayState] = useState(
    PlayState.stopEnd
  )
  useEffect(() => {
    setAnimationPlayState(PlayState.play)
  }, [])

  return error ? (
    <Tween
      playState={animationPlayState}
      duration={0.1}
      from={{ height: 0 }}
      to={{ height: "auto" }}
    >
      <div className={styles.error}>{error}</div>
    </Tween>
  ) : (
    <Tween
      playState={animationPlayState}
      duration={0.1}
      from={{ height: 40 }}
      to={{ height: 0 }}
    >
      <div className={styles.errorPlaceholder}>&nbsp;</div>
    </Tween>
  )
}
