import React from "react"

const logo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 25">
    <path
      fillRule="evenodd"
      d="M21.603.125v5.93a.125.125 0 01-.125.124h-3.956a.125.125 0 01-.125-.125V.124A.125.125 0 0017.272 0H.125A.125.125 0 000 .125v24.75c0 .07.056.125.125.125h6.279a.125.125 0 00.124-.125V6.303c0-.069.056-.124.124-.124h4.093c.068 0 .124.055.124.124v18.572c0 .07.055.125.124.125h6.28a.125.125 0 00.124-.125V12.513c0-.069.056-.125.125-.125h3.956c.07 0 .125.056.125.125v12.362c0 .07.056.125.125.125h6.279a.124.124 0 00.124-.125V6.303c0-.069.056-.124.124-.124h4.093c.068 0 .124.055.124.124v18.572c0 .07.056.125.124.125h6.28a.125.125 0 00.124-.125V.125A.125.125 0 0038.875 0H21.728a.125.125 0 00-.125.125"
    />
  </svg>
)

export default logo
