export class UrlUtility {
  /**
   *
   * @param url {string}
   * @param keep {Array<string>}
   */
  removeQueryParams(url, keep = []) {
    if (typeof url !== "string") {
      return undefined
    }

    const urlObject = new URL(url)
    const urlWithoutSearchParams = urlObject.origin + urlObject.pathname
    if (Array.isArray(keep) && keep.length < 1) {
      return urlWithoutSearchParams
    }

    const oldParams = new URLSearchParams(urlObject.search)
    const newUrlObject = new URL(urlWithoutSearchParams)

    keep.forEach((parameterName) => {
      const parameterValue = oldParams.get(parameterName)
      if (parameterValue === null) {
        return
      }
      newUrlObject.searchParams.append(parameterName, parameterValue)
    })

    return newUrlObject.toString()
  }

  addProtocolToUrl(url) {
    if (typeof url !== "string") {
      return undefined
    }

    const protocol =
      (typeof window !== "undefined" && window.location.protocol) || "https:"

    if (/^\/\/?/i.test(url)) {
      return `${protocol}${url}`
    }

    if (!/^https?:\/\//i.test(url)) {
      return `${protocol}//${url}`
    }

    return url
  }
}
