import React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import styles from "./Holder.module.scss"

export const Holder = React.forwardRef(({ children, className }, ref) => {
  return (
    <div className={cx(styles.holder, className)} ref={ref}>
      {children}
    </div>
  )
})

Holder.propTypes = {
  children: PropTypes.node,
}

Holder.defaultProps = {
  children: null,
  className: null,
}
