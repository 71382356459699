import React, { Component } from "react"
import PropTypes from "prop-types"
import cx from "classnames"

import ButtonArrow from "./ButtonArrow"
import styles from "./Button.module.scss"

export class Button extends Component {
  render() {
    const {
      children,
      className: classNameProp,
      variant,
      type,
      dark,
      full,
      disabled,
      ...rest
    } = this.props

    const className = cx(
      styles.button,
      {
        [styles.buttonPrimary]: variant === "primary",
        [styles.buttonSecondary]: variant === "secondary",
        [styles.buttonDark]: dark,
        [styles.buttonFull]: full,
        [styles.buttonDisabled]: disabled,
      },
      classNameProp
    )

    const isPrimary = variant === "primary"

    return (
      <button type={type} className={className} {...rest}>
        {children}
        {isPrimary && <ButtonArrow />}
      </button>
    )
  }
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  variant: PropTypes.oneOf(["primary", "secondary"]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  dark: PropTypes.bool,
  full: PropTypes.bool,
  disabled: PropTypes.bool,
}

Button.defaultProps = {
  variant: "secondary",
  type: "button",
  dark: false,
  full: false,
  disabled: false,
}
