import React, { Component } from "react"
import cx from "classnames"
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { Headroom } from "components/headroom/Headroom"
import { Link } from "components/link/Link"
import Logo from "./assets/logo.js"
import styles from "./Header.module.scss"

export class MantraHeader extends Component {
  constructor(props) {
    super(props)

    this.state = {
      route: props.location ? this.getRoute(props.location.pathname) : null,
      fullscreen: false,
      pinned: false,
      scrollPercent: 0,
    }

    this.wrapper = React.createRef()
  }

  getRoute = (path) => {
    if (!path || path === "/") {
      return "/"
    } else {
      return path.split("/")[1]
    }
  }

  handleToggleOnClick = () => {
    this.setState({ fullscreen: !this.state.fullscreen })

    if (!this.state.fullscreen) {
      disableBodyScroll(this.wrapper.current)
    } else {
      enableBodyScroll(this.wrapper.current)
    }
  }

  handleLinkOnClick = (e) => {
    const { route } = this.state
    const target = this.getRoute(e.target.pathname)

    if (target === route) {
      e.preventDefault()
      window.scrollTo(0, 0)
    }

    this.setState({ fullscreen: false })
    enableBodyScroll(this.wrapper.current)
  }

  getScrollPercent = () => {
    var h = document.documentElement,
      b = document.body,
      st = "scrollTop",
      sh = "scrollHeight"
    return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
  }

  checkScroll = () => {
    this.setState({ scrollPercent: this.getScrollPercent() })
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.checkScroll)
  }
  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.checkScroll)
    clearAllBodyScrollLocks()
  }

  render() {
    const { isInner } = this.props
    const { fullscreen, pinned } = this.state

    const Links = (props) => (
      <>
        <Link
          to="/mantra/category/thoughts/"
          onClick={(e) => this.handleLinkOnClick(e)}
          className={props.navLinkClass}
          activeClassName={styles.navLinkActive}
        >
          Thoughts
        </Link>
        <Link
          to="/mantra/category/research/"
          onClick={(e) => this.handleLinkOnClick(e)}
          className={props.navLinkClass}
          activeClassName={styles.navLinkActive}
        >
          Research
        </Link>
        <Link
          to="/mantra/category/insights/"
          onClick={(e) => this.handleLinkOnClick(e)}
          className={props.navLinkClass}
          activeClassName={styles.navLinkActive}
        >
          Insights
        </Link>
        <Link
          to="/mantra/category/interviews/"
          onClick={(e) => this.handleLinkOnClick(e)}
          className={props.navLinkClass}
          activeClassName={styles.navLinkActive}
        >
          Interviews
        </Link>
      </>
    )

    return (
      <Headroom
        onPin={() => this.setState({ pinned: true })}
        onUnpin={() => this.setState({ pinned: false })}
        onUnfix={() => this.setState({ pinned: false })}
      >
        <div
          ref={this.wrapper}
          className={cx(styles.wrapper, {
            [styles.wrapperMantra]: true,
            [styles.fullscreen]: fullscreen,
            [styles.hidden]: isInner && pinned,
            [styles.pinned]: pinned,
          })}
        >
          <div className={styles.meta}>
            <Link
              to="/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.logo, styles.logoMantra)}
              title="Martian &amp; Machine"
            >
              <Logo />
            </Link>
            <Link
              to="/mantra/"
              onClick={(e) => this.handleLinkOnClick(e)}
              className={cx(styles.navLink, styles.navLinkMantra)}
            >
              Mantra Home
            </Link>
            <button
              onClick={this.handleToggleOnClick}
              className={cx(styles.toggle, {
                [styles.toggleActive]: fullscreen,
              })}
            >
              {fullscreen ? "Close" : "Menu"}
            </button>
          </div>
          <div className={styles.nav}>
            <Links navLinkClass={styles.navLink} />
          </div>
        </div>
      </Headroom>
    )
  }
}
