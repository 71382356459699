import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import { UrlUtility } from "../../utilities/UrlUtility"
import sharingImage from "./assets/martian-sharing-image.png"

const urlUtility = new UrlUtility()

const siteUrl = process.env.GATSBY_SITE_URL

export const Meta = ({
  description,
  seoDescription,
  lang,
  meta,
  title,
  seoTitle,
  headerImage,
  noIndex,
  canonical,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            url
          }
        }
      }
    `
  )

  const metaTitle = title
    ? `${title} | Martian & Machine`
    : site.siteMetadata.title
  const metaSeoTitle = seoTitle ? `${seoTitle} | Martian & Machine` : metaTitle

  const metaDescription = description
  const metaSeoDescription = seoDescription || metaDescription

  const defaultImageUrl = `${siteUrl}${sharingImage}`
  const image = headerImage ? headerImage.fixed.src : defaultImageUrl
  const absoluteUrlImage = urlUtility.addProtocolToUrl(image)
  /**
   * this is needed because react escapes special characters when creating HTML output on server side,
   * and therefore breaks the link to image because the Contentful images CDN API
   * does not support escaped parameter format, e.g. http://www.example.com/my-image?w=1500&amp;q=50
   * Keeping only one parameter works because ampersand is not escaped
   */
  const imageWithoutUnnecessaryParams = urlUtility.removeQueryParams(
    absoluteUrlImage,
    ["w"]
  )

  const fields = [
    {
      property: `og:title`,
      content: metaTitle,
    },
    {
      property: `og:image`,
      content: imageWithoutUnnecessaryParams,
    },
    {
      property: `og:type`,
      content: `website`,
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:title`,
      content: metaTitle,
    },
    {
      name: `twitter:image`,
      content: imageWithoutUnnecessaryParams,
    },
    {
      property: `og:site_name`,
      content: `martian.ventures`,
    },
    {
      name: "facebook-domain-verification",
      content: "z23kgc15g2lei3ov9j99uv4umc13j0",
    },
  ].concat(meta)

  if (metaSeoDescription) {
    fields.push({
      name: `description`,
      content: metaSeoDescription,
    })
  }

  if (metaDescription) {
    fields.push({
      property: `og:description`,
      content: metaDescription,
    })
    fields.push({
      name: `twitter:description`,
      content: metaDescription,
    })
  }

  if (noIndex) {
    fields.push({
      name: "robots",
      content: "noindex",
    })
  }

  const links = useMemo(() => {
    // counter needed for TTNormsPro
    // loading the counter async with low priority (print media)
    // @see https://www.filamentgroup.com/lab/load-css-simpler/
    const allLinks = [
      {
        rel: "stylesheet",
        href: "https://hello.myfonts.net/count/39f1c5",
        media: "print",
      },
    ]

    if (canonical) {
      allLinks.push({
        rel: "canonical",
        href: `${siteUrl}${canonical}`,
      })
    }

    return allLinks
  }, [canonical])

  return (
    <Helmet
      encodeSpecialCharacters={false}
      htmlAttributes={{
        lang,
      }}
      title={metaSeoTitle}
      meta={fields}
      link={links}
    />
  )
}

Meta.defaultProps = {
  lang: `en`,
  meta: [],
  description: undefined,
}

Meta.propTypes = {
  description: PropTypes.string,
  seoDescription: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  seoTitle: PropTypes.string,
  headerImage: PropTypes.shape({
    fixed: PropTypes.shape({
      src: PropTypes.string,
    }),
  }),
  noIndex: PropTypes.bool,
}
