import cx from "classnames"
import styles from "./SectionHeading.module.scss"
import { animate } from "../../utilities/animate"
import PropTypes from "prop-types"
import React from "react"

export const SectionHeading = (props) => {
  const { children, className, order, animated, marginBottom } = props

  return (
    <h6
      className={cx(styles.title, className, {
        [styles.unordered]: !order,
        [styles.marginBottomSm]: marginBottom === "sm",
      })}
      {...animate({ disabled: !animated })}
    >
      <span className={styles.text}>{order || " "}</span>
      {children}
    </h6>
  )
}

SectionHeading.propTypes = {
  children: PropTypes.node,
  animated: PropTypes.bool,
  marginBottom: PropTypes.oneOf(["sm", "md"]),
}

SectionHeading.defaultProps = {
  children: null,
  animated: false,
}
