import React, { useState } from "react"
import PropTypes from "prop-types"
import axios from "axios"
import cx from "classnames"
import { useForm } from "react-hook-form"
import { Checkbox, Input } from "../../form"
import { Button } from "../../button"
import PreFooter from "../../pre-footer/PreFooter"
import researchImage from "./assets/research-footer.jpg"
import researchImage2x from "./assets/research-footer@2x.jpg"
import footerImage from "./assets/newsletter-footer.jpg"
import footerImage2x from "./assets/newsletter-footer@2x.jpg"
import { animate } from "../../../utilities/animate"
import s from "./NewsletterFooter.module.scss"

const NewsletterFooter = (props) => {
  const {
    isResearch,
    researchAttachment,
    researchAttachmentUrl,
    researchName,
    newsletterImageUrl,
  } = props

  const attachmentUrl = researchAttachmentUrl
    ? researchAttachmentUrl
    : researchAttachment?.file?.url

  const [formState, setFormState] = useState({
    submitting: false,
    subscribed: !isResearch,
    success: null,
    message: null,
  })

  const handleServerResponse = (success, message) => {
    setFormState((prevState) => ({
      ...prevState,
      submitting: false,
      success: success,
      message: message,
    }))
  }

  const onSubmit = (values) => {
    if (values.check !== "") {
      // honeypot
      return
    }

    setFormState((prevState) => ({ ...prevState, submitting: true }))

    const isDefaultForm = values.form === "default"
    const isResearchForm = values.form === "research"

    let data = {
      email: values.email,
    }

    if (isResearchForm) {
      data = {
        name: values.name,
        email: values.email,
        attachmentUrl: attachmentUrl,
        researchName: researchName,
        newsletterImageUrl,
      }
    }

    if (values.subscribe) {
      setFormState((prevState) => ({
        ...prevState,
        subscribed: true,
      }))
    }

    if (isDefaultForm || (isResearchForm && values.subscribe)) {
      axios({
        method: "POST",
        url: process.env.GATSBY_NEWSLETTER_CONFIRM,
        // url: "http://localhost:3000/newsletter/confirmation",
        data: data,
      })
        .then((r) => {
          handleServerResponse(true)
        })
        .catch((r) => {
          handleServerResponse(false, "Error")
        })
    } else {
      handleServerResponse(true)
    }
  }

  const DefaultThankYou = () => (
    <div>
      <h2 className={cx(s.title, "serif")}>Thank you!</h2>
      <div className={cx(s.smallContainer)}>
        <p className={cx([s.paragraph, "h5"])}>
          Confirm your address on the email we’ve sent you and receive news and
          new mantras from Mars on a monthly basis.
        </p>
      </div>
    </div>
  )

  const DefaultContent = () => {
    const { register, handleSubmit, errors } = useForm()

    return (
      <div>
        <h4 className={s.title}>Subscribe to Martian Newsletter</h4>
        <h5 className={s.subtitle}>
          Receive company news and updates about the startup community in your
          inbox.
        </h5>
        <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
          <Input
            name="form"
            value="default"
            type="hidden"
            innerRef={register}
          />
          <Input name="check" value="" type="hidden" innerRef={register} />
          <div className={cx(s.formField, s.formFieldInput)}>
            <Input
              name="email"
              type="text"
              placeholder="your@email.com"
              innerRef={register({
                required: "Please fill in this field.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
                  message: "Invalid email address.",
                },
              })}
              error={errors.email && errors.email.message}
            />
          </div>
          <div className={cx(s.formField, s.formFieldCheckbox)}>
            <Checkbox
              id="subscribe-1"
              name="subscribe"
              type="checkbox"
              label={
                <span className={s.label}>
                  I agree to{" "}
                  <a
                    href="/privacy-policy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                </span>
              }
              innerRef={register({
                required: "This option is mandatory.",
              })}
              error={errors.subscribe && errors.subscribe.message}
            />
          </div>
          <div className={cx(s.formField, s.formFieldButton)}>
            <Button
              variant="primary"
              type="submit"
              disabled={formState.submitting}
              className={s.button}
            >
              {formState.submitting ? "Submitting..." : "Subscribe"}
            </Button>
          </div>
        </form>
      </div>
    )
  }

  const ResearchThankYou = () => (
    <div>
      <h2 className={cx(s.title, "serif")}>Thank you for your information!</h2>
      <div className={cx(s.smallContainer)}>
        <p className={cx([s.paragraph, "h5"])}>
          We need to confirm your email address. To become a Martian, please
          click on the link in the email we’ve just sent you.
        </p>
      </div>
    </div>
  )

  const ResearchContent = () => {
    const { register, handleSubmit, errors } = useForm()

    return (
      <div>
        <h2 className={cx(s.title, "serif")}>Get this report</h2>
        <form onSubmit={handleSubmit(onSubmit)} className={s.form}>
          <Input
            name="form"
            value="research"
            type="hidden"
            innerRef={register}
          />
          <Input name="check" value="" type="hidden" innerRef={register} />
          <div className={cx(s.formField, s.formFieldInput)}>
            <Input
              name="name"
              type="text"
              placeholder="Your name"
              innerRef={register({
                required: "Please fill in this field.",
              })}
              error={errors.name && errors.name.message}
            />
          </div>
          <div className={cx(s.formField, s.formFieldInput)}>
            <Input
              name="email"
              type="text"
              placeholder="Your email"
              innerRef={register({
                required: "Please fill in this field.",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,15}$/i,
                  message: "Invalid email address.",
                },
              })}
              error={errors.email && errors.email.message}
            />
          </div>
          <div className={cx(s.formField, s.formFieldCheckbox)}>
            <Checkbox
              id="subscribe-1"
              name="subscribe"
              type="checkbox"
              label={<>Subscribe to Martian Mantra</>}
              innerRef={register({
                required: "This option is mandatory.",
              })}
              error={errors.subscribe && errors.subscribe.message}
            />
          </div>
          <p className={cx(s.info, "small")}>
            By subscribing to Martian Mantra I agree to the Martian & Machine{" "}
            <a
              href="/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            .
          </p>
          <div className={cx(s.formField, s.formFieldButton)}>
            <Button
              variant="primary"
              type="submit"
              disabled={formState.submitting}
              className={s.button}
            >
              {formState.submitting ? "Submitting..." : "Get the research"}
            </Button>
          </div>
        </form>
      </div>
    )
  }

  return (
    <PreFooter
      image={{
        alt: "Martian team",
        src: isResearch ? researchImage : footerImage,
        src2x: isResearch ? researchImage2x : footerImage2x,
      }}
      id="newsletter-footer"
      isResearch={isResearch}
    >
      <div
        className={cx(s.container, {
          [s.isResearch]: isResearch,
        })}
        {...animate()}
      >
        {isResearch ? (
          <>
            {!formState.success && <ResearchContent />}
            {formState.success && <ResearchThankYou />}
          </>
        ) : (
          <>
            {!formState.success && <DefaultContent />}
            {formState.success && <DefaultThankYou />}
          </>
        )}
      </div>
    </PreFooter>
  )
}

NewsletterFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isResearch: PropTypes.bool,
  researchAttachment: PropTypes.object,
  researchAttachmentUrl: PropTypes.string,
}

NewsletterFooter.defaultProps = {
  className: "",
  children: undefined,
  isResearch: undefined,
  researchAttachment: undefined,
  researchAttachmentUrl: undefined,
}

export default NewsletterFooter
