import React from "react"
import TransitionLink from "gatsby-plugin-transition-link"
import { withGlobalContext } from "components/context/GlobalContext"

const LinkComponent = (props) => {
  const { children, direction, bg, to, external, ...rest } = props
  const length = 0.8

  if (external) {
    return (
      <a href={to} rel="noopener noreferrer" {...rest}>
        {children}
      </a>
    )
  }

  return (
    <TransitionLink
      to={to}
      exit={{
        length: length,
        trigger: ({ exit, node }) =>
          props.context.transition({
            props: exit,
            node,
          }),
      }}
      entry={{
        delay: length / 2,
      }}
      {...rest}
    >
      {children}
    </TransitionLink>
  )
}

export const Link = withGlobalContext(LinkComponent)
